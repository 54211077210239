exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-form-copy-js": () => import("./../../../src/pages/ContactForm copy.js" /* webpackChunkName: "component---src-pages-contact-form-copy-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/ContactForm.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

